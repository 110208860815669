<template lang="pug">
    section#listagem
        .wrapper
            ul
                li(v-for="item in contents")
                    .tags
                        .tag(v-if="item.content.setor")
                            i
                            span {{ item.content.setor }}
                    .header
                        h3 {{ item.title }}
                        span(v-if="getServicoOnline(item)").flag @ Serviço Online
                    .body
                        p {{ getExcerpt(item) }}
                    .footer
                        router-link(:to="{ name: getPageType(item.namespace), params: { slug: item.slug } }").detalhes Mais detalhes
                        a(:href="getServicoOnline(item)", target="_blank", v-if="getServicoOnline(item)").acessar
                            | Acessar serviço
                            FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")

</template>

<script>
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { props, excerptByWords } from '@/mixins/components'

export default {
  name: 'section-listagem',
  props,
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare
      }
    }
  },
  methods: {
    getPageType (type) {
      switch (type) {
        case 'App/Models/Noticia':
        case 'App\\Models\\Noticia': return 'Noticia'
        case 'App/Models/Service':
        case 'App\\Models\\Service': return 'Servico'
        case 'App/Models/Pagina':
        case 'App\\Models\\Pagina':
        default: return 'Pagina'
      }
    },
    getServicoOnline (item) {
      return item.content.link
    },
    getExcerpt (item) {
      const parser = new DOMParser()
      const document = parser.parseFromString(item.content.description, 'text/html')
      return excerptByWords(document.body.textContent, 45)
    }
  }
}
</script>

<style lang="stylus" scoped src="./ListagemServicos.styl"></style>
