<template lang="pug">
	main#listagemServicos
		BannerGeral(:contents="bannerContent", v-if="!hasBanner && !isOrganogramaPlano && !$store.state.loading")
		Listagem(:contents="resultados", v-if="!$store.state.loading")
</template>

<script>
import pagina from '@/mixins/pagina'
import qs from 'qs'
import Listagem from '@sections/ListagemGeral/ListagemServicos/ListagemServicos'

export default {
  name: 'view-listagem-geral',
  mixins: [pagina],
  components: {
    Listagem
  },
  data () {
    return {
      resultados: []
    }
  },
  computed: {
    bannerContent () {
      return { content: { title: { pt: this.pagina.title } } }
    }
  },
  created () {
    this.loadServicos(this.page)
  },
  methods: {
    loadServicos () {
      this.$axios
        .get('external-service')
        .then(response => this.resultados = response.data)
    }
  }
}
</script>

<style lang="stylus" scoped src="./Servicos.styl"></style>
